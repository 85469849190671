import React from 'react';
import { Video, Spotlight } from '../../../../components';
import Values from './Values';
import './three.css';

const Three = () => {
  return (
    <div className='modul4__three'>
        <div className='hoja__columns'>
          <div className='modul4__three-text'>
          <h1>{Values.title}</h1>
            {Values.paragraphs.map((paragraph, index) =>
                <p key={index}>{paragraph}</p>
            )}
          </div>
          <div className='modul4__three-video'>
            <Video video={Values.video} />
          </div>
        </div>
        <Spotlight list={Values.reflect} />
      </div>
  )
}
export default Three