import React from 'react';
import { Highlight } from '../../../../components';
import Values from './Values';
import './six.css';

const Six = () => {
  return (
    <div className='modul5__six'>
        <Highlight title={Values.title} paragraphs={Values.paragraphs} />
    </div>
  )
}
export default Six