import React from 'react';
import Values from './Values.js';
import { Link } from 'react-router-dom';
import './four.css';

const Four = () => {
return (
    <div className='landing__four'>
        <h1>{Values.title}</h1>
        <p>{Values.text}</p>
        <div className='landing__four-grid'>
        {Values.themes.map((item, index) =>
                <Link key={index} to={item.url}>
                <div>
                    <h1>{item.title}</h1>
                    <p>{item.description}</p>
                </div> 
                </Link>              
        )}
        </div>
    </div>
  )
}
export default Four