import React from 'react';
import { Spotlight, Video } from '../../../../components';
import Values from './Values';
import './five.css';

const Five = (props) => {
  return (
    <div className='modul1__five'>
        <div className='hoja__columns'>
          <div className='modul1__five-text'>
            <h1>{Values.title}</h1>
            {Values.paragraphs.map((paragraph, index) =>
                <p key={index}>{paragraph}</p>
            )}
          </div>
          <div>
            <Video video={Values.video} />
          </div>
        </div>
        <Spotlight list={Values.reflect} />
    </div>
  )
}
export default Five