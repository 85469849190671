const Values = {
    header: {
        title: 'Tips och fördjupning',
        text: 'Här hittar du information och kontakt till andra organisationer som arbetar med dessa frågor. Gå gärna in på länkarna och se vilket stöd som finns',
    },
    paragraphs: [
        <span><a href="https://mind.se/hitta-ratt-kontakt/jourer-och-linjer-om-du-behover-prata/">Mind.se</a> (har en lista om man behöver prata med någon)</span>,
        <span><a href="https://www.1177.se/Stockholm/liv--halsa/vald-overgrepp-och-sexuella-trakasserier/att-bli-utsatt-for-vald-i-nara-relationer/">1177.se</a> (har en lista på hjälporganisationer för våld i nära relationer)</span>,
        <span><a href="https://www.brottsofferjouren.se/brottsofferstod/olika-brottstyper/vald-i-nara-relationer/">Brottsofferjouren</a> (information och möjlighet till kontakt)</span>,
    ],
    menu: [
        'Psykiska problem',
        'Jourtelefoner',
        'Minds stödlinjer',
        'Övriga stödlinjer och organisationer',
    ],
    id: [
        '#one',
        '#two',
        '#three',
        '#four',
    ],
    ending: {
        prev: 'Del 5: Ett starkt civilsamhälle',
        current: 'Del 6: Tips och fördjupning',
        next: 'Del 3: Psykisk hälsa',
    },
}
export default Values;