const Values = {
    text: 'Lyssna till Danial som berättar om sina tankar om vad man kan göra för att möta en person som mår dåligt.',
    video: {
        title: 'Vad borde vi fokusera på?',
        link: 'https://www.youtube.com/embed/ve8syvHmdR0'
    },
    reflect: [
        'Vad har du gjort för att må bättre när du själv har mått dåligt?',
        'På vilket sätt kan du stötta en person som mår dåligt?',
    ],
}
export default Values;