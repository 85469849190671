const Values = {
    title: 'Övriga stödlinjer och organisationer',
    paragraphs: [
        {
            url: 'https://anhorigasriksforbund.se/anhoriglinjen/',
            title: 'Anhöriglinjen',
            text: 'Anhöriglinjen är en nationell stödtelefon och bemannas av anhöriga med stor erfarenhet. Måndag–tisdag, torsdag–fredag 10–12, 13.30–15. Onsdag 19–21. Telefonnummer 0200-239 500.',
        },
        {
            url: 'https://www.bup.se/',
            title: 'BUP',
            text: 'BUP erbjuder psykiatriskt stöd, utredning och behandling för dig som är under 18 år.',
        },
        {
            url: 'http://www.bris.se/',
            title: 'BRIS',
            text: 'BRIS har öppet dygnet runt för dig upp till 18 år. Du kan höra av dig om precis vad du vill. Ring 116 111 eller chatta på bris.se. Vuxna med frågor om barn är välkomna att ringa BRIS stödlinje för vuxna, 077-150 50 50.',
        },        
        {
            url: 'http://www.kvinnofridslinjen.se/',
            title: 'Kvinnofridslinjen',
            text: 'Stödtelefon för dig som utsatts för hot eller våld. Närstående är också välkomna att ringa. Den som svarar har tystnadsplikt. Du som ringer kan vara anonym, och samtalet syns inte på telefonräkningen. Telefon 020-50 50 50.',
        },
        {
            url: 'https://somaya.se/',
            title: 'Somaya',
            text: 'Stödjer kvinnor och barn att ta makten över sina liv från våld och förtryck. Erbjuder samtalsstöd alla vardagar 9–22. Telefon 020-81 82 83.',
        },
        {
            url: 'https://www.tjejzonen.se/fa-stod/chatterna/',
            title: 'Tjejzonen',
            text: 'På Tjejzonen kan du prata med en Storasyster som lyssnar på dig när du känner att du behöver det. Deras stödchatt är öppen måndag–torsdag samt söndag 20–22.',
        },
        {
            url: 'http://www.manscentrum.se/',
            title: 'Manscentrum',
            text: 'Kriscentrum för män.',
        },
        {
            url: 'https://www.friskfri.se/',
            title: 'Frisk & Fri – Riksföreningen mot ätstörningar',
            text: 'Stödjer personer med ätstörningar och dess närstående samt arbetar förebyggande och för en bättre ätstörningsvård. Erbjuder chatt och telefonstöd till både drabbade och närstående.',
        },
        {
            url: 'https://www.brottsofferjouren.se/',
            title: 'Brottsofferjouren',
            text: 'Erbjuder stöd till dig som varit utsatt för, eller vittne till, ett brott. Telefon 116 006.',
        },
        {
            url: 'https://rise-sverige.se/',
            title: 'Rise',
            text: 'Stödförening för vuxna som utsatts för incest eller andra sexuella övergrepp som barn. Erbjuder stöd via chatt och telefon. Telefon 08-696 00 95.',
        },
        {
            url: 'http://www.foreningenstorasyster.se/wordpress/',
            title: 'Storasyster',
            text: 'En ideell organisation som vänder sig till dig som utsatts för våldtäkt eller andra sexuella övergrepp. Erbjuder stöd via chatt.',
        },
    ],
}
export default Values;