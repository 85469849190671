const Values = {
    title: 'Självbilden',
    paragraphs: [
        'Gulderens självbild påverkades starkt både under och efter relationen. Hon skapade en bild av sig själv som någon som alltid gjorde fel, som inte var tillräcklig eller bra. Situationen påverkade även hennes tro. Tillslut använde hon tron som ett verktyg för att stå upp för sig själv och leva vidare.',
    ],
    video: {
        title: 'Självbilden',
        link: 'https://www.youtube-nocookie.com/embed/6h6zYzZkNVw'
    },
    reflect: [
        'Vad kan du göra för att stötta en person vars självbild blivit påverkad av våld i nära relation?',
    ],
}
export default Values;