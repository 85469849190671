const Values = {
    title: 'Att möta en person som mår dåligt',
    paragraphs: [
        <span>Det kan det vara svårt att veta hur man möter en person som mår dåligt på bästa sätt. Ibland kan man råka göra saker som inte hjälper personen alls, även om det görs med god vilja. Här finns några tips på hur du kan tänka. Vill du läsa mer kan du göra det <a href="https://mind.se/rad-och-stod/kanner-du-nagon-som-mar-daligt/?gclid=CjwKCAiAh9qdBhAOEiwAvxIok3aNGatGujx961YWe6OGWpRtMXXwIlvxMmdP5h3OVl_iJynWFtnUPRoCUKYQAvD_BwE"><u>här</u></a>! Observera att om du är rädd för någons liv ska du kontakta vården. Om det är akut ska du ringa 112.</span>,
        <span><strong>Ställ öppna frågor - ge inte råd</strong><br/>Att ställa öppna frågor öppnar upp för att undersöka hur personen mår och hur den själv reflekterar över sitt mående. Det är bättre än att ge råd som personen inte bett om.</span>,
        <span><strong>Visa intresse för personens mående</strong><br/>Det kan ta tid att skapa förtroende hos en person som mår dåligt. Att återkommande visa intresse för en persons mående kan skapa förtroende över tid. Säg inte “jag finns här om du behöver”, det är få som självmant nappar på ett sådant uttryck.</span>,
        <span><strong>Våga fråga</strong><br/>Våga fråga hur personen mår, du kan också ställa direkta frågor. Det är bra att ställa raka frågor som “har du självmordstankar?”. Då är det också viktigt att aktivt lyssna på personens berättelse utan att döma. Om du är orolig för personens liv ska du vända dig till vården.</span>,
        <span><strong>Lyssna och bekräfta</strong><br/>Lyssna aktivt och bekräfta personens känslor och upplevelser. Du kan berätta om vad du hör och ser när personen berättar om sina känslor och upplevelser.</span>,
        <span><strong>Stötta - rädda inte</strong><br/>Du kan inte rädda någon, utan en person måste själv bestämma sig för att skapa förändring. Därför ska du fokusera på att stötta. Reflektera och bolla.</span>,
    ],
}
export default Values;