import React from 'react';
import { Video } from '../../../../components';
import Values from './Values';
import './three.css';

const Three = () => {
  return (
    <div className='modul3__three'>
      <h1>{Values.title}</h1>
      <p>{Values.text}</p>
      <div>
        <Video video={Values.video} />
      </div>
    </div>
  )
}
export default Three