const Values = {
    title: 'Psykisk hälsa i siffror',
    text: 'Folkhälsomyndigheten menar att även om det är tre fjärdedelar av Sverige befolkning som anser att de har ett psykiskt välbefinnande, är det allt fler som också anser att de har olika former av psykiska besvär såsom oro, ångest, sömnbesvär eller nedstämdhet. Faktorer som kön, inkomstnivå, utbildningslängd och ålder påverkar frågan om psykiska besvär. Det är generellt unga, kvinnor, de med lägre inkomst och kortare utbildning som upplever mest psykiska besvär.',
    paragraphs: [
        'Danial berättar varför han har börjat prata om sin psykiska hälsa. Han blev oroad när han fick höra att det finns många som lider utan att kunna hitta rätt hjälp och stöd.',
    ],
    video: {
        title: 'Psykisk ohälsa i siffror',
        link: 'https://www.youtube.com/embed/Qfm6hJSU32Y'
    },
    reflect: [
        'Varför tror du att det finns stigma kring psykisk ohälsa?',
        'Hur kan du arbeta för att motverka stigmatisering?',
        'Hur kan du arbeta för att flera ska våga prata om sin psykiska hälsa?',
    ],
}
export default Values;