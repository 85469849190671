import React, { useState, useEffect } from 'react';
import useScrollListener from '../useScrollListener/UseScrollListener';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.png';
import './navbar.css';

const Menu = (props) => (
  <>
  <p className="navbar__hover-underline-animation"><Link to="/">Start</Link></p>
  <div className="hoja__navbar-dropdown" onMouseEnter={() => props.setToggleTheme(true)} onMouseLeave={() => props.setToggleTheme(false)}>
        <p className="navbar__hover-underline-animation" onMouseEnter={() => props.setToggleTheme(true)} onMouseLeave={() => props.setToggleTheme(false)}>Studiematerial</p>
        <div className={props.toggleTheme ? "hoja__navbar-dropdown_container scale-up-center" : "hoja__navbar-dropdown_container scale-down-center"} onMouseEnter={() => props.setToggleTheme(true)} onMouseLeave={() => props.setToggleTheme(false)}>
          <p><Link className="navbar__hover-underline-animation" to="/Jamstalldhet">Jämställdhet</Link></p>
          <p><Link className="navbar__hover-underline-animation" to="/Vald-i-nara-relation">Våld i nära relation</Link></p>
          <p><Link className="navbar__hover-underline-animation" to="/Psykisk-halsa">Psykisk hälsa</Link></p>
          <p><Link className="navbar__hover-underline-animation" to="/Att-mota-och-bemota">Att möta och bemöta</Link></p>
          <p><Link className="navbar__hover-underline-animation" to="/Ett-starkt-civilsamhalle">Ett starkt civilsamhälle</Link></p>
          <p><Link className="navbar__hover-underline-animation" to="/Tips-och-fordjupning">Tips och fördjupning</Link></p>
        </div>
  </div>
  <div className="hoja__navbar-dropdown" onMouseEnter={() => props.setToggleLinks(true)} onMouseLeave={() => props.setToggleLinks(false)}>
        <p className="navbar__hover-underline-animation" onMouseEnter={() => props.setToggleLinks(true)} onMouseLeave={() => props.setToggleLinks(false)}>Våra webbsidor</p>
        <div className={props.toggleLinks ? "hoja__navbar-dropdown_container scale-up-center" : "hoja__navbar-dropdown_container scale-down-center"} onMouseEnter={() => props.setToggleLinks(true)} onMouseLeave={() => props.setToggleLinks(false)}>
          <p><Link className="navbar__hover-underline-animation" to="https://www.ibnrushd.se/">Ibn Rushd</Link></p>
          <p><Link className="navbar__hover-underline-animation" to="https://demokratisktgoteborg.nu/">Demokratiskt Göteborg</Link></p>
          <p><Link className="navbar__hover-underline-animation" to="https://www.kodexmalmo.com/">Kodex Malmö</Link></p>
        </div>
  </div>
  </>
)

const Navbar = () => {
  const [visible, setVisible] = useState(true);
  const scroll = useScrollListener();
  useEffect(() => {
    if(scroll.y > 150 && scroll.y - scroll.lastY > 0)
      setVisible(false);
    else
      setVisible(true);
  }, [scroll.y, scroll.lastY]);

  const [toggleTheme, setToggleTheme] = useState(false);
  const [toggleLinks, setToggleLinks] = useState(false);

  return (
    <div className={visible ? "hoja__navbar" : "hoja__navbar-hidden"}>
        <div className="hoja__navbar-links">
          <div className='hoja__navbar-links_logo'>
            <Link to="https://www.ibnrushd.se/"><img src={logo} alt="logo" /></Link>
          </div>
          <div className='hoja__navbar-links_container'>
             <Menu toggleTheme={toggleTheme} setToggleTheme={setToggleTheme} toggleLinks={toggleLinks} setToggleLinks={setToggleLinks} />
          </div>
        </div>
        <div className="hoja__navbar-sign">
          <p className="navbar__hover-underline-animation"><Link to="https://www.ibnrushd.se/kontakt/">Kontakta oss</Link></p>
        </div>
    </div>
  )
}

export default Navbar