import React from 'react';
import Values from './Values.js';
import { Video } from '../../../../components';
import './two.css';

const Two = () => {
  return (
    <div className='landing__two'>
      <h1>{Values.title}</h1>
      <div className='hoja__columns'>
        <div className='landing__two-text'>
            <p>{Values.text}</p>
        </div>
        <Video video={Values.video} />
      </div>
      <p>{Values.text2}</p>
      <div className='hoja__columns'>
        <Video video={Values.video2} />
        <div className='landing__two-text'>
            <p>{Values.text3}</p>
        </div> 
      </div>
    </div>
  )
}
export default Two