const Values = {
    title: 'Bygga kapacitet i församlingarna',
    paragraphs: [
        'Många vänder sig till de muslimska församlingarna i frågor kring existentiell hälsa eller frågor kring äktenskap. Vi behöver fortsätta bygga upp kunskapen och kapaciteten att kunna möta människor i församlingarna.',
    ],
    video: {
        title: 'De vanligaste frågorna som folk behöver stöd med',
        link: 'https://www.youtube.com/embed/XVNxDnGyXOc',
    },
    paragraphs2: [
        'En av utmaningarna är den misstro som finns idag. Att det finns en yngre generation som känner att de inte är en del av samhället. Ett sätt kan vara att skapa möte på lika villkor mellan majoritetssamhället och de som känner att de faller utanför.',
    ],
    video2: {
        title: 'Utmaningar i arbetet',
        link: 'https://www.youtube.com/embed/tl8i3v3gAvE',
    },
}
export default Values;