import React from "react";

const Values = {
    header: {
        title: 'Psykisk hälsa',
        text: 'I den här delen av studiematerialet fördjupar vi oss i psykisk hälsa',
    },
    intro: {
        title: 'Psykisk hälsa',
        paragraphs: [
            'När vi människor har en god psykisk hälsa är det möjligt för oss människor att må bra. Den psykiska hälsan påverkas av många olika saker. Folkhälsomyndigheten skriver att psykisk hälsa påverkas av ärftlig sårbarhet, vad vi gör, hur vi lever och vad vi är med om. Men också av de rättigheter, möjligheter eller begränsningar som samhället och vår omgivning ger oss. Psykisk ohälsa orsakar ofta stort lidande i människors liv och påverkar hela samhället.',
        ],
    },
    zero: {
        title: 'Psykisk hälsa är en mänsklig rättighet',
        text: 'I den internationella konventionen om ekonomiska, sociala och kulturella rättigheter finns skrivelser om psykisk hälsa. Artikel 12 i konventionen erkänner rätten för  var och en att åtnjuta bästa möjliga fysiska och psykiska hälsa.',
        info: {
            title: 'FN:s allmäna förklaring om de mänskliga rättigheterna , artikel 25.1',
            text: [
                <span>"Var och en har rätt till en levnadsstandard tillräcklig för den egna och familjens <u>hälsa och välbefinnande</u>, inklusive mat, kläder, bostad, hälsovård och nödvändiga sociala tjänster samt rätt till trygghet i händelse av arbetslöshet, sjukdom, invaliditet, makas eller makes död, ålderdom eller annan förlust av försörjning under omständigheter utanför hans eller hennes kontroll."</span>,
            ],
        },
    },
    menu: [
        'Introduktion',
        'Psykisk hälsa är en mänsklig rättighet',
        'När man mår dåligt',
        'Varför mår man dåligt?',
        'Stigma och ohälsa',
        'Psykisk hälsa i siffror',
        'Att möta en person som mår dåligt',
    ],
    id: [
        '#intro',
        '#zero',
        '#one',
        '#two',
        '#three',
        '#four',
        '#five',
    ],
    ending: {
        prev: 'Del 2: Våld i nära relation',
        current: 'Del 3: Psykisk hälsa',
        next: 'Del 4: Att möta och bemöta',
    },
}
export default Values;