import React from 'react';
import { Spotlight, Video } from '../../../../components';
import Values from './Values';
import './four.css';

const Four = () => {
  return (
    <div className='modul3__four'>
      <div className='hoja__columns'>
        <div className='modul3__four-text'>
          <h1>{Values.title}</h1>
          <p>{Values.text}</p>
          {Values.paragraphs.map((paragraph, index) =>
                  <p key={index}>{paragraph}</p>
              )}
        </div>
        <div>
          <Video video={Values.video} />
        </div>
      </div>
      <Spotlight list={Values.reflect} />
    </div>
  )
}
export default Four