import React from 'react';
import { Video, ListBox, Spotlight } from '../../../../components';
import Values from './Values';
import './one.css';

const One = (props) => {
  return (
    <div className='modul1__one'>
        <div className='hoja__columns'>
          <div className='modul1__one-text'>
          <h1>{Values.title}</h1>
            {Values.paragraphs.map((paragraph, index) =>
                <p key={index}>{paragraph}</p>
            )}
          </div>
          <div className='modul1__one-video'>
            <Video video={Values.video} />
          </div>
        </div>
        <ListBox title={Values.tips.title} list={Values.tips.list} summary={Values.tips.summary} />
        <Spotlight list={Values.reflect} />
      </div>
  )
}
export default One