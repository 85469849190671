const Values = {
    title: 'Teman',
    text: 'I detta studiematerial hittar du fem teman och en sida där du får tips på fördjupning. Klicka på faktarutan för det tema du är intresserad av för att komma till den delen av studiematerialet.',
    themes: [
        {
            url: '/Jamstalldhet',
            title: 'Jämställdhet',
            description: 'I den här delen av studiematerialet ligger fokus på jämställdhet. Jämställdhet handlar om att kvinnor som män ska ha lika mycket makt att forma sitt eget liv och samhället. Det betyder att kvinnor och män ska ha samma rättigheter, skyldigheter och möjligheter.',
        },
        {
            url: '/Vald-i-nara-relation',
            title: 'Våld i nära relation',
            description: 'I den här delen av studiematerialet ligger fokus på våld i nära relation. Våld i nära relation är ett stort jämställdhetsproblem i Sverige. Våld i nära relation handlar om när en närstående utövar våld mot en. Våldet kan se olika ut, men påverkar en människas fysiska och psykiska välmående.',
        },
        {
            url: '/Psykisk-halsa',
            title: 'Psykisk hälsa',
            description: 'I den här delen av studiematerialet ligger fokus på psykisk hälsa. När vi människor har en god psykisk hälsa är det möjligt för oss människor att må bra. Den psykiska hälsan påverkas av många olika saker. Psykisk ohälsa orsakar ofta stort lidande i människors liv och påverkar hela samhället.',
        },
        {
            url: '/Att-mota-och-bemota',
            title: 'Att möta och bemöta',
            description: 'I den här delen av studiematerialet får du utforska olika verktyg som kan hjälpa dig i din vardag när du möter människor som befinner sig i livets olika skeden.',
        },
        {
            url: '/Ett-starkt-civilsamhalle',
            title: 'Ett starkt civilsamhälle',
            description: 'I den här delen hittar du tips och råd för hur din organisation kan arbeta med socialt arbete.',
        },
        {
            url: '/Tips-och-fordjupning',
            title: 'Tips och fördjupning',
            description: 'I den här delen av studiematerialet får du tips om fördjupning för att fortsätta lära dig och utvecklas i din roll.',
        },
    ],
}
export default Values;