import React from 'react';
import { Spotlight, Video } from '../../../../components';
import Values from './Values';
import './three.css';

const Three = (props) => {
  return (
    <div id='modul1__three' className='modul1__three'>
      <div className='hoja__columns'>
        <div>
          <h1>{Values.title}</h1>
          <Video video={Values.video} />
        </div>
        <div className='modul1__three-text'>
          <h2>{Values.subtitle}</h2>
          {Values.paragraphs.map((paragraph, index) =>
                  <p key={index}>{paragraph}</p>
              )}
          <ul>
            {Values.list.map((listItem, index) =>
                <li key={index}><p>{listItem}</p></li>
            )}
          </ul>
        </div>
      </div>
      <Spotlight list={Values.reflect} />
    </div>
  )
}
export default Three