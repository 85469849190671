import React from "react";

const Values = {
    title: 'Normalisering av våld',
    video: {
        title: 'Normalitet av våld',
        link: 'https://www.youtube-nocookie.com/embed/xrzt_dHJaEk',
    },
    subtitle: 'Våldets normaliseringsprocess',
    paragraphs: [
        'Normaliseringsprocessen är ett sätt att förklara den våldsprocess som händer i nära relationer. Den förklarar hur våld kan bli en normal del av en relation. Med tiden börjar den som utsätts för våld förvänta sig våldet och se det som en del av sin vardag. För att kunna hantera en vardag fylld med hot och våld skapar personen strategier för att förklara våldet. Förövaren försvarar sina våldshandlingar genom att skuldbelägga den utsatta. Medan den utsatta då får anpassa sina strategier för att kunna hantera sin utsatthet. Det blir den utsattes överlevnadsstrategi. När våldet normaliseras tar den utsatte många gånger över förövarens verklighetsuppfattning, det kallas för internalisering.',
        <strong>Våldsutövaren normaliserar våldet genom att ha:</strong>,
    ],
    list: [
        'Kontrollerad gränsmarkering: använder kontrollerande våldshandlingar som olika former av våld såsom att hindra personen från att vara en del av sitt nätverk eller anklaga personen för saker som den inte gör.',
        'Isolering som kan vara fysisk och mental; hindra den utsatta att träffa folk, hotar personen eller manipulerar den utsatta till att själv välja bort att träffa folk.',
        'Växling mellan våld och värme: han växlar mellan att vara kärleksfull eller att vara våldsam.',
    ],
    reflect: [
        'Vad kan du som möter en person som misstänks leva i en normaliseringsprocess göra för att motverka den?',
    ],
}
export default Values;