const Values = {
    title: 'Veta vad som är ens roll - och det som inte är ens roll',
    paragraphs: [
        'När man möter människor som mår dåligt är det lätt hänt att man vill rädda personen och ge råd inom alla områden som personen upplever som problem. Men det är viktigt att veta när man kan hjälpa, och när man inte kan hjälpa. Då är det viktigt att hänvisa vidare till rätt instans.',
    ],
    video: {
        title: 'Hur man kan arbeta med konflikter när det gäller två personer/parter. Genom stöd och råd, men även att hänvisa vidare',
        link: 'https://www.youtube.com/embed/UjutP4Y98S8'
    },
    reflect: [
        'Fundera över en situation där du upplevt att du inte längre kan hjälpa en person. Dela den med gruppen.',
        'Reflektera över vad du gjorde bra, och vad du hade kunnat göra bättre.',
    ],
}
export default Values;