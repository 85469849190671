import React from 'react';
import { HashLink } from 'react-router-hash-link';
import './footer.css';
import gpt3Logo from '../../assets/logo.png';

const Footer = () => {
  return (
    <div className="gpt3__footer section__padding">
        <div className="gpt3__footer-links">
          <div className="gpt3__footer-links_div">
            <h4>Länkar</h4>
            <a href='https://www.ibnrushd.se/'><p>Ibn Rushd</p></a>
            <a href='https://demokratisktgoteborg.nu/'><p>Demokratiskt Göteborg</p></a>
            <a href='https://www.kodexmalmo.com/'><p>Kodex Malmö</p></a>
          </div>
          <div className="gpt3__footer-links_div">
            <h4>Meny</h4>
            <HashLink to='/#about'><p>Om HOJA</p></HashLink>
            <HashLink to='/#material'><p>Studiematerial</p></HashLink>
            <HashLink to='/#about2'><p>Om Ibn Rushd</p></HashLink>
          </div>
          <div className="gpt3__footer-links_div">
            <h4>Kontakt</h4>
            <a href='https://goo.gl/maps/eYUxj7WdK68yFh75A'><p>Sundbybergsvägen 1C, 171 73 Solna</p></a>
            <a href='tel:0856244900'><p>08-56 24 49 00</p></a>
            <a href='mailto:info@ibnrushd.se'><p>info@ibnrushd.se</p></a>
            
          </div>
        </div>

        <div className="gpt3__footer-copyright">
            <img src={gpt3Logo} alt="logo" />
        </div>
    </div>
  )
}

export default Footer