const Values = {
    title: 'Att känna egenmakt',
    video: {
        title: 'Hur det påverkar Ruaa',
        link: 'https://www.youtube.com/embed/NxtqbsOFpAI'
    },
    subtitle: 'Makt',
    paragraphs: [
        'Slår man upp synonymer till makt kommer ord som: kraft, styrka och auktoritet. Slår man upp motsatsord till makt kommer ord som: maktlöshet, oförmåga och kraftlöshet. Nationalencyklopedin skriver att ”makt utövas direkt när en aktör A får en aktör B att handla enligt A:s vilja, även om handlingen strider mot B:s vilja eller intressen”.',
        'Men makt är mer än att kunna bestämma över en annan människa. Makt kan vara att ha pengar, att inte vara rädd när man går hem på kvällen eller känna att andra människor lyssnar på en. Makt handlar om hur mycket du kan påverka ditt eget liv, samhället, eller världen. Makt handlar också om den grundläggande makten över sig själv, det vill säga makten att uttrycka vad man känner, tänker och makten att vara den man vill vara eller göra det man vill göra.',
    ],
    subtitle2: 'Vad är egenmakt?',
    paragraphs2: [
        'Egenmakt handlar om självbestämmande. Det betyder att en människa har möjlighet att kunna påverka sin egen tillvaro och liv. Egenmakt uppstår när en människa har kunskap, självförtroende och utrymme att agera. Motsatsen till egenmakt är maktlöshet.',
        'Egenmakt innebär att hitta styrkan inom sig. Med tilltro till sin egen förmåga blir det möjligt att vara aktiv och skaffa sig kontroll över sin situation.',
    ],
    reflect: [
        'Varför är egenmakt viktigt?',
        'Hur kan man bidra till att andra känner egenmakt?',
    ],
} 
export default Values;