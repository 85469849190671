const Values = {
    title: 'Församlingarnas roll',
    paragraphs: [
        'Diakon Maria Kjellsdotter och imam Mustafa Setkic talar om vilka förutsättningar som behövs för det diakonala arbetet. Det diakonala arbetet inom Svenska kyrkan har gjort en resa, så som det ser ut idag har det inte alltid sett ut. Inom de muslimska församlingarna har imamerna redan den teologiska kunskapen, det som skulle kunna komplettera imamernas roll är utökad kunskap och utbildning om det sociala arbetet för att kunna orientera sig i samhället.',
    ],
    video: {
        title: 'Förutsättningar för diakonalt arbete',
        link: 'https://www.youtube.com/embed/43gD9cxPlHg',
    },
    paragraphs2: [
        'Församlingarna kan ge stöd som andra instanser i samhället inte kan ge. De kan ge något helt annat än myndigheter och socialtjänsten. Många som är troende söker sig först till församlingen eller samfundet eftersom de känner sig hemma och trygga där. Församlingarna och samfunden och sociala myndigheter behöver arbeta med tillit och förtroende gentemot varandra och att vi strävar åt samma håll.',
    ],
    video2: {
        title: 'Vad församlingarna kan stötta med',
        link: 'https://www.youtube.com/embed/9RtNJY0phkM',
    },
    reflect: [
        'Vilka förutsättningar finns i er organisation för att kunna möta människor som behöver stöd och hjälp?',
        'Finns det medlemmar i församlingarna som kan bidra till arbetet på olika sätt?',
    ],
}
export default Values;