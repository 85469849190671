import React from 'react';
import './header.css';

const Header = (props) => (
  <div className="hoja__header" id="hoja__header">
        <h1>{props.title}</h1>
        <p>{props.text}</p>
  </div>
);

export default Header;
