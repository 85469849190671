import React from 'react';
import Values from './Values';
import './two.css';

const Two = () => {
  return (
    <div className='modul6__two'>
        <div className='modul6__two-content'>
          <h1>{Values.title}</h1>
          <h2>Polis, ambulans, räddningstjänst</h2>
          <p>Akut 112, annars 114 14. Dygnet runt.</p>
          {Values.paragraphs.map((item, index) =>
                <span key={index}>
                  <a href={item.url} className='modul6__hover-underline-animation'><h2>{item.title}</h2></a>
                  <p>{item.text}</p>  
                </span>
            )}
        </div>
    </div>
  )
}
export default Two