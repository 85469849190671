const Values = {
    title: 'Introduktion till diakonalt och socialt arbete',
    text: 'För att förstå vad det diakonala, sociala arbetet kan innebära och hur det kan skilja sig mellan de kristna och muslimska församlingarna, får du lyssna på diakon Maria Kjellsdotter och imam Mustafa Setkic som introducerar begreppen.',
    video: {
        title: 'Diakonalt och socialt arbete',
        link: 'https://www.youtube.com/embed/rqJO7_ZHdCs',
    },
    text2: 'Diakon Maria Kjellsdotter berättar vad det diakonala arbetet innebär. Det handlar både om att vägleda, stötta men också lyfta röster för de människor som inte får ta plats. Hon berättar även att det finns skillnader mellan det sociala arbetet som görs av socialtjänsten och det som görs av kyrkan. Kyrkan har till exempel  inga journalsystem, men man lyder fortfarande under socialtjänstlagen och har därför anmälningsplikt.',
    video2: {
        title: 'Muslimskt socialt arbete',
        link: 'https://www.youtube.com/embed/6I0uHQBRt18',
    },
    text3: 'Imam Mustafa Setkic lyfter likheterna mellan det diakonala arbetet i kyrkan med imamernas arbete i de muslimska församlingarna. I de muslimska församlingarna sker det ibland mer omedvetet då det inte finns ett begrepp för arbetet och ibland saknas det organisatoriska strukturer. De centrala begrepp som kan knytas till de muslimska församlingarnas sociala arbete är tillit, förtroende, kunskap om att möta personer som har behov, och kunskap om att kunna vägleda och hjälpa till.',
}
export default Values;