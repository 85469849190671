const Values = {
    title: 'Studiematerialet',
    paragraphs: [
        'Detta studiematerial riktar sig till imamer och frivilliga som möter asylsökande och särskilt utsatta människor i moskéerna. Materialet är byggt för att passa en studiecirkel där varje träff har ett tema. Till varje tema får du både läsa och lyssna till personer som upplevt svårigheter, forskare och imamer.',
        'Berättelserna du hör i studiematerialet är personernas egna upplevelser som varje person själv står för.',
    ],
    subtitle: 'Så här funkar studiematerialet',
    paragraphs2: [
        <p>Materialet är byggt för att passa en studiecirkel där varje träff har ett tema.</p>,
        <span>
            <ol>
                <li>Samla ihop en grupp, minst fem personer.</li>
                <li>Avsätt två och en halv timme för varje träff, det blir fem träffar totalt.</li>
                <li>Börja studiecirkeln genom att gå igenom det första temat. Läs gärna texten tillsammans, titta på filmerna och reflektera över frågeställningarna.</li>
                <li>Avsluta sista träffen med en utvärdering där ni samtalar om vad ni lärt er, vad ni tar med er och vad ni vill fortsätta fördjupa er i.</li>
            </ol>
        </span>,
        <p>Kom ihåg att rapportera in din studiecirkel till Ibn Rushd. Du kan även få pedagogiskt stöd av studieförbundet. Hör av dig till <a href='mailto:akademin@ibnrushd.se'>akademin@ibnrushd.se</a>.</p>,
    ],
}
export default Values;