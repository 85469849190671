import React from 'react';
import { Highlight } from '../../../../components';
import Values from './Values';
import './five.css';

const Five = () => {
  return (
    <div className='modul3__five'>
      <Highlight title={Values.title} paragraphs={Values.paragraphs} />
    </div>
  )
}
export default Five