import React from 'react';
import { Spotlight, Video } from '../../../../components';
import Values from './Values';
import './three.css';

const Three = () => {
  return (
    <div className='modul2__three'>
      <div className='hoja__columns'>
        <div>
          <h1>{Values.title}</h1>
          <Video video={Values.video} />
          <Spotlight list={Values.reflect} />
        </div>
        <div className='modul2__three-text'>
          <h1>{Values.subtitle}</h1>
          {Values.paragraphs.map((paragraph, index) =>
                <p key={index}>{paragraph}</p>
            )}
          <h1>{Values.subtitle2}</h1>
          {Values.paragraphs2.map((paragraph, index) =>
                <p key={index}>{paragraph}</p>
            )}
        </div>
      </div>
    </div>
  )
}
export default Three