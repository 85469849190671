import React from "react";

const Values = {
    header: {
        title: 'Våld i nära relation',
        text: 'I den här delen av studiematerialet fördjupar vi oss i våld i nära relation',
    },
    intro: {
        subtitle: 'Vad är våld?',
        paragraphs: [
            'Våld är ett begrepp som kan betyda många olika saker. Det är handlingar som enligt lag är brottsliga, som fysisk våld eller sexuella övergrepp. Det är också handlingar som inte klassas som brott enligt lag men som kan skapa mönster av utsatthet som; förnedrande kommentarer, att ekonomiskt utnyttja någon eller isolering från vänner och familj.',
            'Enligt FN:s deklaration om avskaffande av våld mot kvinnor, som antogs år 1993 definieras mäns våld mot kvinnor som:',
            <em>”Varje könsrelaterad våldshandling som resulterar i, eller troligen kommer att leda till, fysisk, sexuell eller psykisk skada eller lidande för kvinnor, samt hot om sådana handlingar, tvång eller godtyckligt frihetsberövande, vare sig det sker i det offentliga eller privata livet.”</em>,
            'Jämställdhetsmyndigheten beskriver våldet som handlingar riktat mot en annan person. Där handlingen är menad för att skada, smärta, skrämma eller kränka personen. Våldshandlingen kan också få personen att göra saker mot sin egen vilja eller avstå från att göra något som den vill. (Utdrag ur Jämställdhetsmyndigheten)',
        ],
    },
    menu: [
        'Introduktion',
        'När du blir isolerad och fråntas ditt sociala stödsystem',
        'Olika typer av våld',
        'Normalisering av våld',
        'Eskalering av våldet och vägen ut',
        'Självbilden',
        'Möta motståndet och våga jobba med frågan',
    ],
    id: [
        '#intro',
        '#one',
        '#two',
        '#three',
        '#four',
        '#five',
        '#six',
    ],
    ending: {
        prev: 'Del 1: Jämställdhet',
        current: 'Del 2: Våld i nära relation',
        next: 'Del 3: Psykisk hälsa',
    },
}
export default Values;