import React from 'react';
import './highlight.css';

const Highlight = (props) => {
  return (
    <div className="hoja__highlight">
        <div className="hoja__highlight-container">
          <h1>{props.title}</h1>
          {props.paragraphs.map((paragraph, index) =>
                <p key={index}>{paragraph}</p>
            )}
        </div>
    </div>
  )
}

export default Highlight