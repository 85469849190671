import React from "react";

const Values = {
    title: 'När du blir isolerad och fråntas ditt sociala stödsystem',
    paragraphs: [
        'Möt Gulderen. Gulderen är 51 år och har i 22 år levt i en nära relation som har präglats av våld. Du får följa hennes livsberättelse där hon pratar om våldet, hur det påverkat henne vilka konsekvenser det har fått och hur hon har tagit sig vidare.',
        'Gulderen berättar om hur hennes partner isolerade henne från hennes familj och vänner. Hon kände att hon inte fick något stöd och blev ensam. Det påverkar fortfarande hennes relation till familjen idag.',
    ],
    video: {
        title: 'Isolering och begränsade av supportsystem',
        link: 'https://www.youtube-nocookie.com/embed/td5H_jqUdNM'
    },
    tips: {
        title: 'Tecken på våldsutsatthet',
        list: [
            'När någon drar sig undan från vänner och inte längre vill följa med på aktiviteter.',
            'Att personen blir mer tyst och sluten.',
            'Att personen verkar tyngd och bekymrad.',
            'Att personen alltid måste passa tider.',
            'När personen måste förklara vad den har gjort och med vilka den varit.',
            'Att personen beter sig annorlunda när partnern är med.',
            'När personen inte vågar säga emot sin partner.',
            'När personen inte verkar ha tillgång till egna pengar eller på annat sätt verkar vara beroende ekonomiskt av sin partner.',
        ],
        summary: <em>Källa: <a href='https://1177.se'>www.1177.se</a></em>,
    },
    reflect: [
        'Ovan nämns några tecken på våldsutsatthet, men det finns väldigt många. Kan du komma på en annan definition eller finns det något som du tycker är viktigt att nämna?',
        'Hur kan man som närstående stötta en person som blir isolerad av en partner?',
        'Varför är ett stödsystem så viktigt för en person som är utsatt för våld?',
    ],
}
export default Values;