import image1 from '../../../../assets/image1.jpg';
import image2 from '../../../../assets/image2.jpg';
import image3 from '../../../../assets/image3.jpg';
import image4 from '../../../../assets/image4.jpg';
import image5 from '../../../../assets/image5.jpg';

const Values = {
    title: 'Att ta tuffa samtal',
    video: {
        title: 'Tuffa samtal',
        link: 'https://www.youtube.com/embed/rKnGmyya3bw'
    },
    reflect: [
        'I samtalet mellan imamerna lyfts vikten av att både vägleda och förklara vad som är oförenligt med islam, men också att  att hänvisa personer som är utsatta för hot eller våld till berörda myndigheter för att få hjälp. Fundera över när det varit viktigt för dig i din roll att hänvisa personer vidare till andra instanser?',
        'Mäns våld mot kvinnor är ett stort samhällsproblem i Sverige. Imamerna säger att våld är förkastligt. Hur kan du i din roll arbeta för att lyfta dessa frågor?',
    ],
    subtitle: 'Några snabba tips:',
    tips: [
        {
            img: image2,
            alt: 'Picture 1',
            text: <span>Rollen som socialarbetande Imam är nyckeln till att stödja behoven i vår församling.  Därför är det viktigt för oss som hjälpare att vara skickliga med rätt kompetens när vi kommunicerar med medlemmar i vårt samhälle.<br/><br/>Varje person är unik och kommer att ta med sig sina olika problem. Därför bör vi ta upp den hjälpsökande och frågan utifrån deras respektive bakgrund och omständigheter, eftersom varje samtal i sig är olika.</span>,
        },
        {
            img: image5,
            alt: 'Picture 2',
            text: 'När du träffar en person för första gången  är det alltid en bra idé att välkomna genom att presentera dig själv. Du kan till exempel inkludera ditt namn, din organisation, följt av att fråga om hur du kan hjälpa dem.',
        }, 
        {
            img: image3,
            alt: 'Picture 3',
            text: 'När du för ett samtal är det viktigt att vara närvarande i samtalet. Detta är ett av de första stegen för att etablera en förtroendefull relation och kontakt. Det är också viktigt att vara ärlig och empatisk så att personen ska känna sig accepterad och därmed vara mer öppen i att dela med sig av sin berättelse.',
        },
        {
            img: image1,
            alt: 'Picture 4',
            text: 'Var uppmärksam på ditt kroppsspråk. Genom att uppmärksamma och lyssna aktivt kommer du bättre kunna förstå problemet. Tänk också på att undvika att döma och att ge personen utrymme att uttrycka sig. Undvik att avbryta medan en person pratar och ge alltid personen tillräckligt med tid att svara i samtalet.',
        }, 
        {
            img: image4,
            alt: 'Picture 5',
            text: 'Vikten av god kvalitet i kommunikationen mellan hjälpsökande och hjälpare är av stor vikt. Fråga gärna i slutet på samtalet om personen som sökt hjälp upplever att den blivit lyssnad på och hur du som hjälpare kan förändra sitt sätt att kommunicera till nästa gång. Men tänk på att inte sätta press på den som söker hjälp. Du är där för den, inte tvärt om.',
        },
    ],
}
export default Values;