const Values = {
    title: 'Att möta rätt',
    video: {
        title: 'Vad som behövs för att våga prata och öppna sig',
        link: 'https://www.youtube.com/embed/-mf79yFe9wI',
    },
    text: 'Möt Ruaa igen. Hon berättar att det är viktigt att personen som man pratar med förstår en rätt och kan vara inlyssnande. Ibland kan det vara bra att prata med någon som inte känner en sen innan eftersom det ger möjlighet till mer öppenhet.',
    reflect: [
        'I videon berättar Ruaa att språket är viktigt när man möter en person som befinner sig i våld i nära relation. På vilket sätt spelar språket roll?',
        'Kan du komma på andra saker som är viktiga för att möta rätt?',
    ],
}
export default Values;