const Values = {
    title: 'Civilsamhällets roll',
    paragraphs: [
        'Trossamfunden gör stora samhällsinsatser genom att till exempel ge stöd till utsatta grupper. De fyller också en viktig funktion för att möta existentiell ohälsa eftersom många trossamfund finns närvarande för människor i svåra stunder. Samfunden har värdefulla kunskaper att bidra med och en förmåga att stödja människor i kris genom samtal och medmänsklighet.',
        'Imam Akil Zahiri från Imam Ali Center talar om vikten av religiösa center och vilken funktion de fyller i samhället.',
    ],
    video: {
        title: 'Vad trosamfunden bidrar med',
        link: 'https://www.youtube.com/embed/xR0E-QJLJgc',
    },
    paragraphs2: [
        'Imam Khaled Eldib från Stockholms moské berättar att det är de religiösa centrens uppgift att välkomna alla oavsett vem de är för att kunna vägleda människor i moraliska och andliga frågor.',
    ],
    video2: {
        title: 'Vägledning i moraliska och andliga frågor',
        link: 'https://www.youtube.com/embed/bEwdWHyvVek',
    },
    reflect: [
        'Hur ser det ut i er förening, finns det en öppenhet att komma och prata om andliga och moraliska frågor?',
        'Vilken kunskap finns inom föreningen för att kunna vägleda människor som kommer med svåra frågor?',
        'Hur tror du att ni kan utveckla det arbetet?',
    ],
}
export default Values;