import React from 'react';
import Values from './Values.js';
import ibn from '../../../../assets/logo.png';
import './five.css';

const Five = () => {
  return (
    <div className='landing__five'>
        <div className='hoja__columns'>
            <div>
              <h1>{Values.title2}</h1>
              {Values.paragraphs2.map((paragraph, index) =>
                  <p key={index}>{paragraph}</p>
              )}
            </div>
            <img src={ibn} alt='' />
        </div>
    </div>
  )
}
export default Five