const Values = {
    title: 'Psykiska problem',
    paragraphs: [
        <p>Har du akuta psykiska problem kan du få hjälp av den lokala vuxenpsykiatrins jourverksamheter som har en mottagning i varje kommun eller stadsdel. Dessa lokala jourverksamheter har ofta öppet till klockan 22. Vid akuta psykiska problem kan du även ringa 112.</p>,
        <p>Vid allvarliga psykiska problem:</p>,
        <span>
            <ul>
                <li>Är du under 18 år vänder du dig till barn- och ungdomspsykiatrin (BUP).</li>
                <li>Är du 18 år eller äldre vänder du dig till vuxenpsykiatrin. Det finns en mottagning i varje kommun och stadsdel. Du kan själv beställa tid på mottagningen eller komma dit med hjälp av remiss.</li>
            </ul>
        </span>,
        <p>På några ställen i länet och i landet finns även mottagningar för unga, som vänder sig till personer från 16 år och uppåt. Dessa mottagningar drivs gemensamt av barn- och ungdomspsykiatrin och vuxenpsykiatrin.</p>,
        <p>Vid lättare psykiska problem, som sömnsvårigheter, ångest, lättare depressioner eller sorgereaktioner ska du i första hand vända dig till sin vårdcentral för att få hjälp. Husläkare kan, om det behövs, remittera vidare till psykolog, psykosocialt team på vårdcentralen eller till öppenvården inom psykiatrin.</p>,
    ]
}
export default Values;