const Values = {
    title: 'Eskalering av våldet och vägen ut',
    paragraphs: [
        'Gulderens situation blev sämre, innan den blev bättre. Våldet blev värre och hon visste inte hur hon skulle ta sig ur situationen. Hon bestämde sig för att lämna relationen och lyckades hitta en väg ut.',
    ],
    video: {
        title: 'Eskalering och vägen ut',
        link: 'https://www.youtube-nocookie.com/embed/eAeMJ2qQve8'
    },
    reflect: [
        'Varför tror du att det kan vara svårt att prata om våld i nära relation?',
        'Varför tror du det är svårt för andra att lyssna på någon som varit eller befinner sig i en utsatt situation?',
        'Vilket ansvar har personer som möter människor i utsatta situationer, till exempel imamer?',
        'En vanlig fråga som ställs till personer som lever med våld i nära relationer är “Varför lämnar du inte?”. Vad finns det för risker med att ställa den frågan?',
    ],
} 
export default Values;