const Values = {
    title: 'Minds stödlinjer',
    paragraphs: [
        {
            url: 'https://mind.se/hitta-hjalp/sjalvmordslinjen/',
            title: 'Självmordslinjen',
            text: <span>Självmordslinjen är tillgänglig via <a href='https://mind.se/chatt/'>chatt</a> och telefon 90 101 och är för dig som har tankar på att ta ditt liv, eller för dig som har frågor om närstående du har tankar på att ta sitt liv. Du är alltid anonym när du kontaktar oss. Självmordslinjen har öppet dygnet runt, varje dag.</span>,
        },
        {
            url: 'https://mind.se/hitta-hjalp/livslinjen/',
            title: 'Livslinjen',
            text: 'Livslinjen är till för dig mellan 16-25 år som vill chatta med en vuxen. Ibland är livet tufft och då är det bra att prata med någon. Chatten är öppen söndag-torsdag klockan 19-22.',
        },
        {
            url: 'https://mind.se/hitta-hjalp/foraldralinjen/',
            title: 'Föräldralinjen',
            text: 'Är du orolig för ditt barn eller ett barn i din närhet? Ring Föräldratelefonen på 020-85 20 00. Öppet vardagar klockan 10–15 samt torsdagskvällar 19–21.',
        },
        {
            url: 'https://mind.se/hitta-hjalp/aldrelinjen/',
            title: 'Äldrelinjen',
            text: 'Äldrelinjen vänder sig till dig som mår psykiskt dåligt eller längtar efter någon att prata med. Vi har öppet måndag – torsdag klockan 8-19 . Äldrelinjen nås på 020-22 22 33.',
        },
        {
            url: 'https://forum.mind.se/',
            title: 'Mind Forum',
            text: 'Här diskuteras allt det där som kallas livet. Mind Forum är en trygg plats på nätet där du anonymt kan prata om livet när det känns tufft. Du kan dela med dig, ge och få stöd.',
        },
    ]
}
export default Values;