import React from "react";

const Values = {
    title: 'Olika typer av våld',
    paragraphs: [
        'Våld kan se olika ut och ha olika karaktär. En person kan vara utsatt för en kombination av olika våldstyper. Här kan du läsa mer om olika former av våld.',
    ],
    list1: [
        'Fysiskt våld',
        'Psykiskt våld',
        'Sexuellt våld',
        'Materiellt våld',
        'Ekonomiskt våld',
        'Latent våld',
        'Digitalt våld',
        'Gaslighting',
        'Försummelse',
    ],
    list2: [
        <p>Handlingar som att nypas, rivas, knuffas, sparka, släpa i håret, slå, ta stryptag, kväva och att hålla fast.</p>,
        <ul>
            <li><em>Nedvärderande beteende:</em><br/>Verbala kränkningar, att tala illa om den utsatta och att man skuldbelägger den utsatta. Förminskar den utsattes känslor, säger att hen överdriver.</li>
            <li><em>Kontrollerande beteende:</em><br/>Olika strategier för att öka kontroll över den utsatta och styra över personens handlingar. Det kan handla om att spionera på den utsatta, tvinga den utsatta att berätta allt den gör och förfölja den eller anklaga den utsatte för saker som hen inte har gjort.</li>
            <li><em>Fysisk och mental isolering:</em><br/>Det kan visa sig genom att vara svartsjuk mot den utsatta och hindra personen från att vara en del av sitt nätverk. Det kan också vara starka humörsvängningar, hot mot den utsatta eller personens nätverk, eller att hota om självmord om den utsatta lämnar.</li>
        </ul>,
        <ul>
            <li>Våldsutövaren tvingar den våldsutsatta att titta på pornografi.</li>
            <li>Tvinga den utsatta att ha sex med andra.</li>
            <li>Utsätta den utsatta för sexuellt övergrepp när personen sover.</li>
            <li>Tvinga den utsatta till oskyddat sex.</li>
            <li>Att den utsatta blir tagen på mot sin vilja.</li>
            <li>Att bli rörd på ett obehagligt sätt eller när någon säger saker med sexuell anspelning mot den utsattes vilja.</li>
        </ul>,
        <p>Riktar sig mot saker, för att skrämma eller kontrollera den utsatta. Att slå, skära eller kasta saker kan ses som materiellt våld. Det kan också vara att klippa sönder kläder eller kasta saker t.ex. en kniv, mot det utsatta.</p>,
        <p>Ett medel för att skapa kontroll över den utsatta  är genom ekonomiskt våld. Till exempel kan den utsatta behöva redovisa vad den gör med sina pengar, att partnern kontrollerar kontoutdragen och har kontroll över gemensamma pengar. Att tvinga den utsatta att ta lån, skriva under ofördelaktiga äktenskapsförord, teckna bolag eller skriva sig som ägare på fordon mot sin vilja. Det är bara den ena parten som fattar beslut åt hela familjen gällande ekonomiska resurser.</p>,
        <p>Är det våld som hela tiden existerar. Många beskriver latent våld som att stämningen förändras när våldsutövaren finns i närheten och att "något hänger i luften". Stämningen är tryckt och risken för nytt våld är ständigt närvarande och styr det du som utsatt gör och hur du beter dig. Ditt beteende blir strategiskt utifrån våldsutövarens närvaro och stämningen som råder.</p>,
        <p>Digitalt våld styrs av samma mekanismer som annat våld men sker online och via digitala plattformar. Digitalt våld handlar bland annat om kontroll, utpressning och förminskande av ditt handlingsutrymme. Exempel på digitalt våld kan vara att kräva att få tillgång till dina inloggningar på sociala medier, att smygläsa dina sms eller att förhindra dig från att följa/prata med olika personer på dina konton. Digitalt våld styrs av samma mekanismer som annat våld men sker online och via digitala plattformar.</p>,
        <p>Gaslighting handlar om att få offret att tvivla på sig själv och på sina upplevelser. Gaslighting kan se ut på många sätt, men det handlar om att förövaren förnekar och förminskar offrets verklighet. ”Det har aldrig hänt”, ”det har jag inte gjort/sagt” eller ”du överdriver alltid” är vanliga saker förövaren kan säga. Resultatet blir ofta att offret tvivlar på sig själv. Överdriver jag? Hände det där på riktigt? Håller jag på att bli galen?</p>,
        <p>Det är en typ av våld som främst drabbar personer med funktionsnedsättning och äldre. Det sker medvetet för att skada eller skapa otrygghet. Exempelvis att medvetet felaktig medicinera, att lämna personen utan tillsyn, att undanhålla hjälpmedel.</p>,
    ],
    reflect: [
        'Varför är det viktigt att känna till att det finns flera olika uttryck för våld?',
        'Finns det något som är nytt för dig? Något som du inte visste klassades som våld?',
    ],
} 
export default Values;