import React, { useState, useEffect, useLayoutEffect } from 'react';
import { ReactTitle } from 'react-meta-tags';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import { Header, NavMenu, Highlight } from '../../components';
import { ONE, TWO, THREE, FOUR, FIVE, SIX } from './delar';
import Values from './Values';
import './modul1.css';

const Modul1 = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const [active, setActive] = useState(0);

  const {ref: refIntro, inView: viewIntro } = useInView({ threshold: 0.5,});
  const {ref: refOne, inView: viewOne } = useInView({ threshold: 0.7,});
  const {ref: refTwo, inView: viewTwo } = useInView({ threshold: 0.5,});
  const {ref: refThree, inView: viewThree } = useInView({ threshold: 0.5,});
  const {ref: refFour, inView: viewFour } = useInView({ threshold: 0.5,});
  const {ref: refFive, inView: viewFive } = useInView({ threshold: 0.5,});
  const {ref: refSix, inView: viewSix } = useInView({ threshold: 0.8,});

  useEffect(() => {
    if(viewSix)
      setActive(6);
    else if(viewFive)
      setActive(5);
    else if(viewFour)
      setActive(4);
    else if(viewThree)
      setActive(3);
    else if(viewTwo)
      setActive(2);
    else if(viewOne) 
      setActive(1);
    else if(viewIntro) 
      setActive(0);
    
  }, [viewIntro, viewOne, viewTwo, viewThree, viewFour, viewFive, viewSix]);

  return (
    <div id='modul1' className='hoja__modul1'>
      <ReactTitle title="HOJA | Våld i nära relation"/>
      <NavMenu  menu={Values.menu} active={active} id={Values.id} />
      <div className='hoja__modul1-header section__padding'>
        <Header
        title={Values.header.title}
        text={Values.header.text} />
      </div>
      <div className='hoja__modul1-intro section__padding' ref={refIntro}>
        <span id='intro' className='anchor' />
        <Highlight 
        title={Values.intro.subtitle}
        paragraphs={Values.intro.paragraphs} />
      </div>
      <div className='hoja__modul1-one section__padding' ref={refOne}><span id='one' className='anchor' /><ONE /></div>
      <div className='hoja__modul1-two section__padding'  ref={refTwo}><span id='two' className='anchor' /><TWO /></div>
      <div className='hoja__modul1-three section__padding' ref={refThree}><span id='three' className='anchor' /><THREE /></div>
      <div className='hoja__modul1-four section__padding' ref={refFour}><span id='four' className='anchor' /><FOUR /></div>
      <div className='hoja__modul1-five section__padding' ref={refFive}><span id='five' className='anchor' /><FIVE /></div>
      <div className='hoja__modul1-six section__padding' ref={refSix}><span id='six' className='anchor' /><SIX /></div>
      <div className='hoja__modul1-ending section__padding'>
        <Link to='/Jamstalldhet' className='hoja__modul1-ending_prev'>
          <h2>Föregående</h2>
          <p>{Values.ending.prev}</p>
        </Link>
        <div>
          <h2>Aktuell</h2>
          <p>{Values.ending.current}</p>
        </div>
        <Link to='/Psykisk-halsa' className='hoja__modul1-ending_next'>
          <h2>Nästa</h2>
          <p>{Values.ending.next}</p>
        </Link>
      </div>
    </div>
  )
}
export default Modul1