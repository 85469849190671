import React from 'react';
import { Video, Spotlight } from '../../../../components';
import Values from './Values';
import './three.css';

const Three = () => {
  return (
    <div className='modul5__three'>
        <h1>{Values.title}</h1>
        <div className='hoja__columns'>
          <div>
            <Video video={Values.video} />
          </div>
          <div>
            {Values.paragraphs.map((paragraph, index) =>
                <p key={index}>{paragraph}</p>
            )}
          </div>
        </div>
        <div className='hoja__columns'>
          <div>
            <Video video={Values.video2} />
          </div>
          <div>
            {Values.paragraphs2.map((paragraph, index) =>
                <p key={index}>{paragraph}</p>
            )}
          </div>
        </div>
        <Spotlight list={Values.reflect} />
      </div>
  )
}
export default Three