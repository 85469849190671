const Values = {
    title: 'Vad är en imams roll?',
    paragraphs: [
        'I samtalet mellan imamerna Akil Zahiri från Imam Ali Center  och Khaled Eldib från Stockholms Moské, lyfter de frågan om vad som är en imams roll. Ur samtalet går det att förstå att en imams roll är mångfacetterad. Bland annat finns rollen som rådgivare.',
    ],
    video: {
        title: 'Vilka frågor brukar ställas av de som kommer och vad är imamens roll?',
        link: 'https://www.youtube.com/embed/iGYTYXGtvhY'
    },
    reflect: [
        'Fundera över hur din roll ser ut. Vilka olika roller brukar du ta på dig?',
        'I samtalet lyfter Khaled Eldib att man som imam inte kan ha svar på alla frågor och att det därför är viktigt att hänvisa till rätt instanser. Fundera över hur det kan se ut i din egen roll?',
    ],
}
export default Values;