import React from 'react';
import { Spotlight, Video } from '../../../../components';
import Values from './Values';
import './four.css';

const Four = (props) => {
  return (
    <div className='modul2__four'>
      <div className='hoja__columns'>
          <div>
            <h1>{Values.title}</h1>
            <p>{Values.text}</p>
          </div>
          <div>
            <Video video={Values.video} />
          </div>
      </div>
      <Spotlight list={Values.reflect} />
    </div>
  )
}
export default Four