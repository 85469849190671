const Values = {
    title: 'Möta motståndet och våga jobba med frågan',
    video: {
        title: 'Vad skulle man kunna göra',
        link: 'https://www.youtube-nocookie.com/embed/K1V9JmTNVoU'
    },
    paragraphs: [
        'När en en våldsutsatt samlar kraft och berättar om sin situation är det viktigt att bemöta det, att personen inte möts av motstånd. Många gånger krävs det tid att skapa förtroende för den våldsutsatta att ens kunna nämna att hen är utsatt. Ibland vet personen inte ens om att den utsätts för våld. Det kräver ett arbete byggt på tillit, förtroende och återkommande samtal.',
        'Gulderen pratar om församlingarnas roll att kunna möta dessa typer av frågor. Hon ser att det är viktigt att prata om relationer i ett tidigt stadie. Relationer kan vara svåra, därför kan vara viktigt att ha samtal med någon som kan vägleda båda personerna i relationen.',
    ],
    reflect: [
        'Vad kan du göra för att stötta en person som lever eller har levt i en nära relation där det finns eller har funnits olika former av våld?',
        'Ofta är det svårt att ensam bära på vetskapen om att en person är utsatt för våld. Vad behöver du för att stötta en person som är våldsutsatt?',
    ],
    subtitle: 'Skulle du vara utsatt för våld finns det personer som kan stötta och hjälpa dig:',
    tips: [
        'Brottsofferjouren: ring 116 006',
        'Kvinnofridslinjen: ring 020-50 50 50',
        'Kvinnojour eller socialtjänst',
        'Polisen: ring 114114 eller 112 (om det är akut)',
    ],
    summary: 'Fler kontaktuppgifter finns under avsnittet “Vidare kontakt”.',
}
export default Values;