import React from 'react';
import { Highlight } from '../../../../components';
import Values from './Values';
import './one.css';

const One = () => {
  return (
    <div className='modul5__one'>
      <Highlight paragraphs={Values.text} />    
    </div>
  )
}
export default One