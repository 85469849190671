import React, { useLayoutEffect } from 'react';
import { ReactTitle } from 'react-meta-tags';
import { ONE, TWO, THREE, FOUR, FIVE } from './delar';
import hoja from '../../assets/Hoja_hands_frilagda_big.png';
import './landing.css';

const Landing = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div className='hoja__landing'>
      <ReactTitle title="HOJA | Startsida"/>
      <div className='hoja__landing-header section__padding'>
        <div className='hoja__columns'>
          <div>
            <h1>HOJA</h1>
            <h2>Ett studiematerial för socialarbetare inom församlingar</h2>
          </div>
          <img  alt='Hoja logo' src={hoja} />
        </div>
      </div>

      <div className='hoja__landing-one section__padding'><span id='about' className='anchor' /><ONE /></div>
      <div className='hoja__landing-two section__padding'><TWO /></div>
      <div className='hoja__landing-three section__padding'><span id='material' className='anchor' /><THREE /></div>
      <div className='hoja__landing-four section__padding'><FOUR /></div>
      <div className='hoja__landing-five section__padding'><span id='about2' className='anchor' /><FIVE /></div>
    </div>
  )
}
export default Landing