import React from 'react';
import { ListBox, Spotlight } from '../../../../components';
import Values from './Values';
import './two.css';

const Two = () => {
  return (
    <div className='modul2__two'>
      <h1>{Values.title}</h1>
      <ListBox title={Values.box.title} list={Values.box.list} summary='' />
      <Spotlight list={Values.reflect} />
    </div>
  )
}
export default Two