import React from 'react';
import Values from './Values';
import './one.css';

const One = () => {
  return (
    <div className='modul6__one'>
        <div className="modul6__one-highlight">
          <div className="modul6__one-highlight_container">
            <h1>{Values.title}</h1>
            {Values.paragraphs.map((paragraph, index) =>
                  <span key={index}>{paragraph}</span>
              )}
          </div>
      </div>
    </div>
  )
}
export default One