const Values = {
    header: {
        title: 'Jämställdhet',
        text: 'I den här delen av studiematerialet fördjupar vi oss i jämställdhet',
    },
    intro: {
        title: 'Jämställdhet',
        paragraphs: [
            'Enligt Jämställdhetsmyndigheten handlar jämställdhet om att kvinnor och män ska ha lika mycket makt att forma sina egna liv och samhället. Det betyder att kvinnor och män ska ha samma rättigheter, skyldigheter och möjligheter.',
            'Fakta visar att Sverige inte är ett jämställt land. Bland annat har kvinnor lägre lön än män, kvinnor utsätts för mer våld i nära relation och kvinnors hälsa är sämre än mäns hälsa, bland annat psykisk hälsa.',
        ],
        reflect: [
            'Hur märker du av ojämställdhet i din vardag?',
            'På vilket sätt kan du bidra till jämställdhet?',
        ],
    },
    menu: [
        'Introduktion',
        'Ojämställdhet påverkar i flera led',
        'Sveriges jämställdhetspolitiska mål',
        'Att känna egenmakt',
        'Att möta rätt',
    ],
    id: [
        '#intro',
        '#one',
        '#two',
        '#three',
        '#four',
    ],
    ending: {
        prev: 'Del 1: Jämställdhet',
        current: 'Del 1: Jämställdhet',
        next: 'Del 2: Våld i nära relation',
    },
}
export default Values;