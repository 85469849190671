import React, { useLayoutEffect } from 'react';
import { ReactTitle } from 'react-meta-tags';
import { Link } from 'react-router-dom';
import './missing.css';

const Missing = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div className='hoja__missing'>
        <ReactTitle title="HOJA | Sidan finns ej"/>
        <div className=' hoja__missing-container'>
            <h1>Fel kod 404!</h1>
            <h2>Denna sida existerar ej...</h2>
            <Link to='/'><div><h2>Tillbaka till framsidan</h2></div></Link>
        </div>
    </div>
  )
}
export default Missing