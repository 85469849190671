import React from 'react';
import { Spotlight, Video } from '../../../../components';
import Values from './Values';
import './six.css';

const Six = () => {
  return (
    <div className='modul3__six'>
      <div className='hoja__columns'>
        <div><p>{Values.text}</p></div>
        <div>
          <Video video={Values.video} />
        </div>
      </div>
      <Spotlight list={Values.reflect} />
    </div>
  )
}
export default Six