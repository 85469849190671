const Values = {
    title: 'Vikten av att bygga nätverk',
    paragraphs: [
        'Som organisation kan det vara svårt att veta hur man ska möta människor i svåra stunder. Forskarna Mohammad Fazlhashemi och Emin Poljarevic samtalar om att civilsamhället och trossamfunden kan hjälpa varandra genom att bygga nätverk.',
    ],
    video: {
        title: 'Överkomma hinder i det sociala arbetet',
        link: 'https://www.youtube.com/embed/8mG80k4vC-E'
    },
    paragraphs2: [
        'Församlingarna kompletterar det sociala arbetet som ger i övriga samhället. En församling eller diakon kan följa med under en längre tid. Vi ser även hur hela livet hos en människa; kropp, själ och ande hänger ihop. Det saknas även ett genuint intresse från sociala myndigheter att närma sig de muslimska församlingarna. Det finns en misstro.  Därför är det viktigt att kroka arm med allierade som till exempel Svenska kyrkan.',
    ],
    video2: {
        title: 'Församlingarnas sociala arbete',
        link: 'https://www.youtube.com/embed/DrGIm2g6m9k'
    },
    reflect: [
        'Vilka aktörer kan vara relevant att ha kontakt med utifrån frågor om socialt arbete?',
        'Vad har ni för nätverk med andra föreningar både inom det muslimska civilsamhället men även med andra aktörer?',
        'Hur kan ni utöka och bygga vidare på samverkan med andra aktörer kring dessa frågor?',
    ],
}
export default Values;