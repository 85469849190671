const Values = {
    title: 'Ojämställdhet påverkar i flera led',
    paragraphs: [
        'Möt Ruaa. Hon är 43 år gammal. För åtta år sedan flydde hon och hennes familj till Sverige från Syrien. Du får följa Ruaas berättelse som handlar om hennes systers tragiska bortgång och hur det påverkade hennes egen livshistoria. Ruaas historia handlar om hur ojämställdhet och våld i nära relation får konsekvenser i flera led och påverkar fler än bara en person.',
        '2003 förlorade Ruaa en av sina systrar till våld i nära relation. Hon berättar om systerns liv och historia. Rua var den enda som visste vad som hände systern, en börda som hon fortfarande bär med sig.',
        'Ruaa berättar om hur det som hände hennes syster påverkade henne själv som person. Först ville hon inte gifta sig, när hon väl gifte sig vågade hon inte säga ifrån till sin man. När hon kom till Sverige blev situationen värre. Hon hamnade plötsligt på sjukhus, det blev vändpunkten för henne och började prioritera sig själv och sin hälsa'
    ],
    video: {
        title: 'Ojämställdhet påverkar i flera led',
        link: 'https://www.youtube.com/embed/_v8e2ixuIig',
    },
    reflect: [
        'Ruaa berättar att hennes systers livsöde påverkade hennes egen romantiska relation. Varför är det viktigt att känna till att våld i nära relation får konsekvenser för flera personer?',
        'Har du tänkt på att våld i nära relation kan påverka flera personer?',
    ],
}
export default Values;