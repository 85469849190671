import React from 'react';
import { Spotlight } from '../../../../components';
import Collapsible from 'react-collapsible';
import { RiArrowDownSLine } from 'react-icons/ri';
import Values from './Values';
import './two.css';

const Two = (props) => {
  return (
    <div id='modul1__two' className='modul1__two'>
      <h1>{Values.title}</h1>
      <div className='modul1__two-text'>
        {Values.paragraphs.map((paragraph, index) =>
                  <p key={index}>{paragraph}</p>
              )}
      </div>
      <div className='modul1__two-boxes'>
        {Values.list1.map((item, index) =>
                  <Collapsible trigger={<div><h3>{ item}</h3><RiArrowDownSLine size={34} /></div>} key={index} transitionTime={200}>
                    {Values.list2[index]}
                  </Collapsible>
                )}
      </div>
      <Spotlight list={Values.reflect} />
    </div>
  )
}
export default Two