const Values = {
    title: 'Att skapa förtroende',
    paragraphs: [
        'Det kan ta tid att skapa förtroende hos en person som mår dåligt. Att återkommande visa intresse för en persons mående kan skapa förtroende över tid.',
    ],
    video: {
        title: 'Vikten av att skapa förtroende och tillit',
        link: 'https://www.youtube.com/embed/4YRcluq03GA'
    },
    reflect: [
        'I samtalet lyfts tillit utifrån tre aspekter: den religiösa ledarens ställning, kunskap/kompetens och sekretess. Samtala i gruppen kring vad du tror att det innebär för din roll när du möter människor som mår dåligt?',
        'Att vara uppriktig med vilka frågor man behärskar och inte är viktigt för att skapa tillit. På vilket sätt kan man göra det?',
    ],
}
export default Values;