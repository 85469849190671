import React, { useState, useEffect, useContext } from 'react';
import useScrollListener from '../useScrollListener/UseScrollListener';
import { slide as Menu } from 'react-burger-menu';
import { RiMenu3Line, RiArrowRightLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import Collapsible from 'react-collapsible';
import './sidebar.css';

const MyContext = React.createContext();

const MyProvider = (props) => {
    const [menuOpenState, setMenuOpenState] = useState(false)

    return (
        <MyContext.Provider value={{
            isMenuOpen: menuOpenState,
            toggleMenu: () => setMenuOpenState(!menuOpenState),
            stateChangeHandler: (newState) => setMenuOpenState(newState.isOpen)
        }}>
            {props.children}
        </MyContext.Provider>
    )
}

const Navigation = () => {
    const ctx = useContext(MyContext)

    const [visible, setVisible] = useState(true);
    const scroll = useScrollListener();
    useEffect(() => {
      if(scroll.y > 150 && scroll.y - scroll.lastY > 0)
        setVisible(false);
      else
        setVisible(true);
    }, [scroll.y, scroll.lastY]);

    const [theme, setTheme] = useState(false)
    const clickTheme = () => {
        setTheme(!theme)
    }

    const [links, setLinks] = useState(false)
    const clickLinks = () => {
        setLinks(!links)
    }

    return (
        <Menu 
                right
                customBurgerIcon={ <RiMenu3Line /> }
                customCrossIcon={ <RiArrowRightLine /> }
                isOpen={ctx.isMenuOpen}
                onStateChange={(state) => ctx.stateChangeHandler(state)}
                disableAutoFocus
                burgerButtonClassName={visible ? "" : "bm-burger-button_hidden"}
                bodyClassName={ 'body__menu-open' } >
                <Link to="/" onClick={ ctx.toggleMenu } >
                    <div>
                        <p className="sidebar__hover-underline-animation">Start</p>
                    </div>
                </Link>
                <Collapsible trigger={
                    <div onClick={ clickTheme }>
                        <p className="sidebar__hover-underline-animation">Studiematerial</p>
                        <div className={!theme ? '' : 'clicked'}>
                            <span></span>
                            <span></span>
                        </div>
                    </div> 
                } transitionTime={200}>
                    <ul>
                        <Link to="/Jamstalldhet" onClick={ ctx.toggleMenu } ><li><p className="sidebar__hover-underline-animation">Jämställdhet</p></li></Link>
                        <Link to="/Vald-i-nara-relation" onClick={ ctx.toggleMenu } ><li><p className="sidebar__hover-underline-animation">Våld i nära relation</p></li></Link>
                        <Link to="/Psykisk-halsa" onClick={ ctx.toggleMenu } ><li><p className="sidebar__hover-underline-animation">Psykisk hälsa</p></li></Link>
                        <Link to="/Att-mota-och-bemota" onClick={ ctx.toggleMenu } ><li><p className="sidebar__hover-underline-animation">Att möta och bemöta</p></li></Link>
                        <Link to="/Ett-starkt-civilsamhalle" onClick={ ctx.toggleMenu } ><li><p className="sidebar__hover-underline-animation">Ett starkt civilsamhälle</p></li></Link>
                        <Link to="/Tips-och-fordjupning" onClick={ ctx.toggleMenu } ><li><p className="sidebar__hover-underline-animation">Tips och fördjupning</p></li></Link>
                    </ul>
                </Collapsible>
                <Collapsible trigger={
                    <div onClick={ clickLinks }>
                        <p className="sidebar__hover-underline-animation">Våra webbsidor</p>
                        <div className={!links ? '' : 'clicked'}>
                            <span></span>
                            <span></span>
                        </div>
                    </div> 
                } transitionTime={200}>
                    <ul>
                        <Link to="https://www.ibnrushd.se/" onClick={ ctx.toggleMenu } ><li><p className="sidebar__hover-underline-animation">Ibn Rushd</p></li></Link>
                        <Link to="https://demokratisktgoteborg.nu/" onClick={ ctx.toggleMenu } ><li><p className="sidebar__hover-underline-animation">Demokratiskt Göteborg</p></li></Link>
                        <Link to="https://www.kodexmalmo.com/" onClick={ ctx.toggleMenu } ><li><p className="sidebar__hover-underline-animation">Kodex Malmö</p></li></Link>
                    </ul>
                </Collapsible>
                <Link to="https://www.ibnrushd.se/kontakt/" onClick={ ctx.toggleMenu } >
                    <div>
                        <p className="sidebar__hover-underline-animation">Kontakta oss</p>
                    </div>
                </Link>        
            </Menu>
    )
}

const Sidebar = () => {
    return (
        <MyProvider>
            <Navigation />
        </MyProvider>
    );
}

export default Sidebar;