import React from 'react';
import { Spotlight, Video } from '../../../../components';
import Values from './Values';
import './four.css';

const Four = (props) => {
  return (
    <div className='modul1__four'>
        <h1>{Values.title}</h1>
        <div className='hoja__columns'>
          <div>
            <Video video={Values.video} />
          </div>
          <div className='modul1__four-text'>
            {Values.paragraphs.map((paragraph, index) =>
                <p key={index}>{paragraph}</p>
            )}
          </div>
        </div>
        <Spotlight list={Values.reflect} />
    </div>
  )
}
export default Four