import React, { useState, useEffect, useLayoutEffect } from 'react';
import { ReactTitle } from 'react-meta-tags';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import { Header, NavMenu } from '../../components';
import { ONE, TWO, THREE, FOUR } from './delar';
import Values from './Values';
import './modul6.css';

const Modul6 = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const [active, setActive] = useState(0);

  const {ref: refOne, inView: viewOne } = useInView({ threshold: 0.9,});
  const {ref: refTwo, inView: viewTwo } = useInView({ threshold: 1.0,});
  const {ref: refThree, inView: viewThree } = useInView({ threshold: 0.8,});
  const {ref: refFour, inView: viewFour } = useInView({ threshold: 0.4,});

  useEffect(() => {
    if(viewOne)
      setActive(0);
    else if(viewTwo)
      setActive(1);
    else if(viewThree)
      setActive(2);
    else if(viewFour)
      setActive(3);
  }, [viewOne, viewTwo, viewThree, viewFour]);

  return (
    <div id='modul6' className='hoja__modul6'>
      <ReactTitle title="HOJA | Tips och fördjupning"/>
      <NavMenu  menu={Values.menu} active={active} id={Values.id} />
      <div className='hoja__modul6-header section__padding'>
        <Header
        title={Values.header.title}
        text={Values.header.text} />
      </div>
      <div className='hoja__modul6-one section__padding' ref={refOne}><span id='one' className='anchor' /><ONE /></div>
      <div className='hoja__modul6-two section__padding'  ref={refTwo}><span id='two' className='anchor' /><TWO /></div>
      <div className='hoja__modul6-three section__padding' ref={refThree}><span id='three' className='anchor' /><THREE /></div>
      <div className='hoja__modul6-four section__padding' ref={refFour}><span id='four' className='anchor' /><FOUR /></div>
      <div className='hoja__modul6-ending section__padding'>
        <Link to='/Ett-starkt-civilsamhalle' className='hoja__modul6-ending_prev'>
          <h2>Föregående</h2>
          <p>{Values.ending.prev}</p>
        </Link>
        <div>
          <h2>Aktuell</h2>
          <p>{Values.ending.current}</p>
        </div>
        <div className='hoja__modul6-ending_next'>
          <h2>Nästa</h2>
          <p>{Values.ending.next}</p>
        </div>
      </div>
    </div>
  )
}
export default Modul6