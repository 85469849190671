import React from "react";

const Values = {
    title: 'Varför mår man dåligt?',
    paragraphs: [
        'Psykisk hälsa är ett ord som fångar in olika psykiska tillstånd. WHO (World Health Organization) definierar psykisk hälsa som något mer än frånvaron av psykisk ohälsa. Enligt dem handlar det om ett tillstånd av psykiskt välbefinnande som gör att vi människor kan klara av alla livets prövningar. Det är ett tillstånd där vi mår psykiskt bra och kan bygga goda relationer och ta beslut. Psykisk hälsa är ofta avgörande för personlig utveckling, men också gruppers och samhällets utveckling.',
        <span>Det kan finnas många anledningar till att man mår dåligt, och allt behöver inte betyda att man lider av psykisk ohälsa. Ibland kan man känna sig arg, stressad, ledsen eller orolig. Det är vanliga känslor som ofta går över av sig själv. <a href="https://www.1177.se/Stockholm/liv--halsa/psykisk-halsa/att-ma-daligt/"><u>1177</u></a> har listat några anledningar till varför man kan må dåligt:</span>,
    ],
    list1: [
        'Stress',
        'Sorg',
        'Kris',
        'Nedstämdhet',
        'Depression',
        'Oro och ångest',
        'Panikångest',
    ],
    list2: [
        'Du kan känna dig stressad om du har mycket att göra och för lite tid, men också om du känner att du har för höga krav och förväntningar på dig. Även för låga krav och förväntningar kan vara stressande. Stress är en naturlig reaktion i kroppen som är bra, om du behöver göra något snabbt eller har bråttom. Men om du är stressad ofta eller inte får tid att återhämta dig kan stressen bli ett problem.',
        'Om du har förlorat något eller någon kan du behöva sörja det som har hänt. Det kan ta tid att sörja, men det hjälper dig att bearbeta och acceptera. Att sörja tar olika lång tid för olika personer. En del kan behöva prata mycket om det som har hänt mycket och ta lång tid på sig. Andra kommer över sorgen tidigare. Det kan vara så att sorgen finns kvar hela livet. Ofta förändras dina känslor med tiden och det kan bli lättare att acceptera det som hänt.',
        'En kris kan vara en reaktion på en händelse eller en förändring i ditt liv. Det kan vara en plötslig händelse som du inte har kunnat förbereda dig på. Det kan också vara något som du har levt med ett tag. Det är vanligt att känna tomhet och förtvivlan i samband med en kris. Du kan också känna dig ensam, övergiven eller uppleva att du har förlorat makten över ditt liv.',
        'Alla känner sig nedstämda då och då. När du är nedstämd kan du må mycket dåligt och det kan kännas som att det alltid kommer att vara så. Då kan det vara bra att tänka på att nedstämdhet oftast går över, även om det ibland kan ta ett tag.',
        'En depression är något annat än att vara ledsen eller tillfälligt nere. Det kan vara en depression om du ihållande känner dig mycket ledsen, irriterad, arg, eller nedstämd under flera veckor.',
        'Oro är en naturlig reaktion som kan hjälpa dig att förbereda dig för utmaningar, hot eller svåra situationer. Men om du är orolig under en längre tid kan det påverka dig negativt. Ångest är en stark känsla av oro och rädsla. Ångest kan kännas mycket starkt i kroppen och det kan vara svårt att förstå att det du känner är något psykiskt. Det kan vara mycket obehagligt att känna ångest, men det går oftast över efter en stund.',
        'Ångest som kommer plötsligt och oväntat kallas för panikångest.',
    ],
    reflect: [
        'Varför är det viktigt att prata om och att arbeta med psykisk hälsa?',
        'På vilket sätt kan psykisk ohälsa påverka en person?',
    ],
}
export default Values;