const Values = {
    title: 'Jourtelefoner',
    paragraphs: [
        {
            url: 'https://www.1177.se/',
            title: '1177',
            text: '1177, Dygnet runt.',
        },
        {
            url: 'https://www.jourhavande-medmanniska.se/',
            title: 'Jourhavande medmänniska',
            text: 'Ibland behöver du dela sina upplevelser, sina tankar och känslor med någon som lyssnar. Jourhavande medmänniska är till för dig som söker medmänskligt stöd på natten och nås på 08-702 16 80.',
        },
        {
            url: 'https://giftinformation.se/',
            title: 'Giftinformationscentralen',
            text: '010-45 66 700, dygnet runt.',
        },
    ]
}
export default Values;