import React from 'react';
import Values from './Values.js';
import { Highlight } from '../../../../components';
import './three.css';

const three = () => {
return (
    <div className='landing__three'>
        <Highlight title={Values.title} paragraphs={Values.paragraphs} />
        <div className="landing__three-highlight">
          <div className="landing__three-highlight_container">
            <h1>{Values.subtitle}</h1>
            {Values.paragraphs2.map((paragraph, index) =>
                  <span key={index}>{paragraph}</span>
              )}
          </div>
      </div>
    </div>
  )
}
export default three