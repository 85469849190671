const Values = {
    why: {
        title: 'Varför HOJA?',
        paragraphs: [
            'Varje år söker sig människor till moskéerna i livets olika skeden. De flesta församlingar bedriver idag ett brett socialt arbete. Imamer och frivilliga hjälper människor i fysisk, psykisk och andlig nöd. Församlingar och humanitära organisationer tar emot tusentals asylsökande varje år som behöver stöd för en framtida etablering i Sverige.',
            'Men personerna de möts av är ofta frivilliga och saknar formell utbildning för att hjälpa och hänvisa vidare till rätt instans. Syftet med detta studiematerial är att förstärka den religiösa vägledningen med kompetensutveckling i hälso- och jämställdhetsfrågor.',
            'En imam har en viktig religiös roll men en imam arbetar även som socialarbetare vars arbetsuppgifter varierar allteftersom behoven skiftar. En imam arbetar med bland annat barn och unga, äldre och sjuka, nyanlända och asylsökande, samt människor i olika utsatta situationer som behöver stöd genom samtal och själavård. En imam möter även människor som behöver praktiskt stöd vid till exempel förlust av anhörig eller andra livskriser. Därmed riktar studiematerialet sig till imamer och frivilliga som möter asylsökande och särskilt utsatta människor i moskéerna. Materialet är ett viktigt steg till att formalisera en utbildning för en muslimsk diakoni i Sverige.',
        ],
    },
    what: {
        title: 'Vad är HOJA?',
        paragraphs: [
            'Bakom det nationella projektet HOJA– hälsa och jämställdhet hos asylsökande står Förenade islamiska föreningar i Sverige (FIFS), Islamic Relief och Islamiska Shiasamfunden i Sverige (ISS) i samverkan med Ibn Rushd studieförbund.',
            'Under ett och ett halvt år har projektet identifierat och belyst det sociala arbetet som sker inom församlingar. Efterfrågan har varit stor och totalt har 140 praktiker deltagit i våra workshop under handledning av handledare. En viktig funktion som projektet fyllt har varit att knyta samman alla de personer som verkar inom det sociala arbetet. Ett nätverk som lever vidare efter projektets slut.',
            'Projektet har redan fått effekt. Både imamer och frivilliga upplever att projektet har stärkt deras kapacitet. En av framgångsfaktorerna är att studiematerialet utvecklats tillsammans med målgruppen, ihop med akademiker, nyanlända och asylsökande i asylprocesser.',
        ],
    },
}
export default Values;