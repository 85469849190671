const Values = {
    header: {
        title: 'Ett starkt civilsamhälle',
        text: 'I det här avsnittet hittar du tankar och råd för hur din organisation kan arbeta med socialt arbete',
    },
    menu: [
        'Ett starkt civilsamhälle',
        'Civilsamhällets roll',
        'Församlingarnas roll',
        'Bygga kapacitet i församlingarna',
        'Vikten av att bygga nätverk',
        'Tips',
    ],
    id: [
        '#one',
        '#two',
        '#three',
        '#four',
        '#five',
        '#six',
    ],
    ending: {
        prev: 'Del 4: Att möta och bemöta',
        current: 'Del 5: Ett starkt civilsamhälle',
        next: 'Del 6: Tips och fördjupning',
    },
}
export default Values;