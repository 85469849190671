const Values = {
    title: 'Att vara en god lyssnare',
    paragraphs: [
        'En viktig del i att möta en människa som mår dåligt är att vara en god lyssnare. Att vara en god lyssnare innebär att vara en aktiv lyssnare. Som aktiv lyssnare låter du den som mår dåligt styra samtalet utifrån dennes egna behov.',
    ],
    video: {
        title: 'Att vara en god lyssnare och tillgänglig',
        link: 'https://www.youtube.com/embed/yZv3AoSRwH4'
    },
    reflect: [
        'Imamerna samtalar om att det är viktigt att vara tillgänglig när man inlett ett förtroende hos en person som mår dåligt. Att det kan vara bra att tänka långsiktigt och vara tillgänglig för flera samtal för att skapa kontinuitet. Fundera över vilka hinder och möjligheter du ser i ett sådant typ av arbete i din egen roll?',
    ],
}
export default Values;