import React from "react";

const Values = {
    title: 'Sveriges jämställdhetspolitiska mål',
    box: {
        title: 'I Sverige arbetar regeringen efter sex jämställdhetspolitiska delmål för att uppnå jämställdhet. Här nedan kan  du ta del av dessa:',
        list: [
            <span><strong>En jämn fördelning av makt och inflytande</strong><br/>Kvinnor och män ska ha samma rätt och möjlighet att vara aktiva medborgare och att forma villkoren för beslutsfattandet.</span>,
            <span><strong>Ekonomisk jämställdhet</strong><br/>Kvinnor och män ska ha samma möjligheter och villkor i fråga om betalt arbete som ger ekonomisk självständighet livet ut.</span>,
            <span><strong>Jämställd utbildning</strong><br/>Kvinnor och män, flickor och pojkar ska ha samma möjligheter och villkor när det gäller utbildning, studieval och personlig utveckling.</span>,
            <span><strong>Jämn fördelning av det obetalda hem- och omsorgsarbetet</strong><br/>Kvinnor och män ska ta samma ansvar för hemarbetet och ha möjligheter att ge och få omsorg på lika villkor.</span>,
            <span><strong>Jämställd hälsa</strong><br/>Kvinnor och män, flickor och pojkar ska ha samma förutsättningar för en god hälsa samt erbjudas vård och omsorg på lika villkor.</span>,
            <span><strong>Mäns våld mot kvinnor ska upphöra</strong><br/>Kvinnor och män, flickor och pojkar, ska ha samma rätt och möjlighet till kroppslig integritet.</span>,
        ],
    },
    reflect: [
        'Ovan listas Sveriges sex  jämställdhetspolitiska mål. Reflektera kring hur du inom ditt sammanhang kan bidra till varje mål.',
        'Kan du komma på flera områden som är viktiga inom jämställdhet?',
    ],
} 
export default Values;