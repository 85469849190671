import React from 'react';
import { Video, Spotlight } from '../../../../components';
import Values from './Values';
import './five.css';

const Five = () => {
  return (
    <div className='modul4__five'>
        <div className='hoja__columns'>
          <div className='modul4__five-video'>
            <h1>{Values.title}</h1>
            <Video video={Values.video} />
          </div>
          <div>
            <Spotlight list={Values.reflect} />
          </div>
        </div>  
        <h1>{Values.subtitle}</h1>
        {Values.tips.map((item, index) =>
                <div key={index} className='modul4__five-tip'>
                    <div>
                        <h1>{index + 1}.</h1>
                        <img src={item.img} alt={item.alt} />    
                    </div>
                    <p key={index}>{item.text}</p>
                </div>
            )} 
      </div>
  )
}
export default Five