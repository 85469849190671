import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Sidebar, Navbar, GoTop } from './components';
import { Landing, Modul1, Modul2, Modul3, Modul4, Modul5, Modul6, Footer, Missing } from './containers';
import './App.css';

const App = () => {
  return (
    <div className="App" id="outer-container">
        <BrowserRouter>
          <Sidebar />
          <Navbar />
          <Routes>
              <Route index element={<Landing />} />
              <Route path='Vald-i-nara-relation' element={<Modul1 />} />
              <Route path='Jamstalldhet' element={<Modul2 />} />
              <Route path='Psykisk-halsa' element={<Modul3 />} />
              <Route path='Att-mota-och-bemota' element={<Modul4 />} />
              <Route path='Ett-starkt-civilsamhalle' element={<Modul5 />} />
              <Route path='Tips-och-fordjupning' element={<Modul6 />} />
              <Route path="*" element={<Missing />} />
          </Routes>
          <Footer />
          <GoTop />
        </BrowserRouter>
    </div>
  )
}
export default App;
