const Values = {
    title: 'När man mår dåligt',
    paragraphs: [
        'Möt Danial. Danial är 20 år och arbetar i familjeföretaget, boxas och studerar. Han har under sitt liv upplevt psykisk ohälsa flera gånger, dels när han var barn men också som vuxen. I dag mår han bra och vill berätta om sin resa från psykisk ohälsa till att kunna hantera den och må bättre.',
    ],
    video: {
        title: 'När man mår dåligt',
        link: 'https://www.youtube.com/embed/Tjq8Tuuvsuc'
    },
    reflect: [
        'Alla har mått dåligt någon gång. Fundera på någon gång när du själv har mått dåligt. Dela gärna i gruppen.',
    ],
}
export default Values;