import React from 'react';
import { Spotlight, Video, ListBox } from '../../../../components';
import Values from './Values';
import './six.css';

const Six = (props) => {
  return (
    <div className='modul1__six'>
      <div className='hoja__columns'>
          <div>
            <Video video={Values.video} />
          </div>
          <div className='modul1__six-text'>
            <h1>{Values.title}</h1>
            {Values.paragraphs.map((paragraph, index) =>
                  <p key={index}>{paragraph}</p>
              )}
          </div>
      </div>
      <Spotlight list={Values.reflect} />
      <ListBox title={Values.subtitle} list={Values.tips} summary={Values.summary} />
    </div>
  )
}
export default Six