import React from "react";

const Values = {
    header: {
        title: 'Att möta och bemöta',
        text: 'I det här avsnittet hittar du tips, råd och verktyg för att kunna möta människor som mår dåligt eller lever i utsatta situationer',
    },
    intro: {
        subtitle: 'Vad är våld?',
        paragraphs: [
            'Våld är ett begrepp som kan betyda många olika saker. Det är handlingar som enligt lag är brottsliga, som fysisk våld eller sexuella övergrepp. Det är också handlingar som inte klassas som brott enligt lag men som kan skapa utsatthet som; förnedrande kommentarer, att ekonomiskt utnyttja någon eller isolering från vänner och familj.',
            'Enligt FN:s deklaration om avskaffande av våld mot kvinnor, som antogs år 1993 definieras mäns våld mot kvinnor som:',
            <em>”Varje könsrelaterad våldshandling som resulterar i, eller troligen kommer att leda till, fysisk, sexuell eller psykisk skada eller lidande för kvinnor, samt hot om sådana handlingar, tvång eller godtyckligt frihetsberövande, vare sig det sker i det offentliga eller privata livet.”</em>,
            'Jämställdhetsmyndigheten beskriver våldet som handlingar riktat mot en annan person. Där handlingen är menad för att skada, smärta, skrämma eller kränka personen. Våldshandlingen kan också få personen att göra saker mot sin egen vilja eller avstå från att göra något som den vill. (Utdrag ur Jämställdhetsmyndigheten)',
        ],
    },
    menu: [
        'Vad är en imams roll?',
        'Veta vad som är ens roll - och det som inte är ens roll',
        'Att skapa förtroende',
        'Att vara en god lyssnare',
        'Att ta tuffa samtal',
    ],
    id: [
        '#one',
        '#two',
        '#three',
        '#four',
        '#five',
    ],
    ending: {
        prev: 'Del 3: Psykisk hälsa',
        current: 'Del 4: Att möta och bemöta',
        next: 'Del 5: Ett starkt civilsamhälle',
    },
}
export default Values;