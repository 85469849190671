const Values = {
    title: 'Stigma och ohälsa',
    text: <span>Ibland kan det vara svårt att prata om psykisk ohälsa. Det kan bero på flera anledningar. Men en anledning kan vara att det finns ett stigma. Ordet stigma kommer från grekiskan och betyder “märke”. Stigmatisering kopplat till psykisk ohälsa handlar om negativa föreställningar eller idéer om personer med psykisk ohälsa. <a href="https://www.folkhalsomyndigheten.se/contentassets/6191b7bece1e4e25b69cc291cfedbdf2/stigmatisering-kopplad-psykisk-ohalsa.pdf"><u>Folkhälsomyndigheten</u></a> skriver att personer med olika psykiatriska tillstånd kan drabbas av dubbel bestraffning eftersom de “utöver sin sjukdom, också kan behöva bemöta samhällets fördomar”. Det finns också forskning som visar att människor väljer att inte söka vård alls på grund av stigmatisering, eftersom de inte vill få stämpeln “psykiskt sjuk”.<br/><br/> Lyssna till Danial som berättar om sina erfarenheter av stigmatisering.</span>,
    paragraphs: [
        'Lyssna till Danial som berättar om sina erfarenheter av stigmatisering.',
    ],
    video: {
        title: 'Stigmatisering',
        link: 'https://www.youtube.com/embed/GQZwgPdyGUA'
    },
}
export default Values;