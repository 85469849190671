import React, { useState, useEffect } from 'react';
import useScrollListener from '../useScrollListener/UseScrollListener';
import { BiCheckboxSquare } from 'react-icons/bi';
import './navMenu.css';

const NavMenu = (props) => {
  const [visible, setVisible] = useState(true);
  const scroll = useScrollListener();
  useEffect(() => {
    if(scroll.y < 460 || scroll.y > document.documentElement.offsetHeight - 1450) {
      setVisible(false);
    }
    else {
      setVisible(true);
    } 
  }, [scroll.y, scroll.lastY]);

  const [active, setActive] = useState(0)
  useEffect(() => {
    setActive(props.active)
  }, [props.active]);

  return (
    <div className={visible ? 'hoja__navmenu move-forward' : 'hoja__navmenu-hidden move-back'}>
      <div className='hoja__navmenu-waypoints'>
        {props.menu.map((item, index) =>
            <div key={index}>
              <p className='buffer'>{item}</p>
              <BiCheckboxSquare className={active === index ? 'waypoint-active' : 'waypoint'} />
              <a href={props.id[index]}><p className={active === index ? 'p-active navmenu__hover-underline-animation-active' : 'navmenu__hover-underline-animation'}>{item}</p></a>
            </div>        
        )}
        </div>
        <div className='hoja__navmenu-line'>
          <div className='line' />
        </div>
    </div>
  )
}

export default NavMenu